import { getApi } from '../../api';

const user = {
  namespaced: true,
  state: {
    user: {
      id: '',
      fullName: '',
      email: '',
      isActive: true,
      admin: false,
      createdAt: '',
      updatedAt: '',
    },
    isAuthenticated: false,
  },
  mutations: {
    setIsAuthenticated(state, { isAuthenticated}) { state.isAuthenticated = isAuthenticated },
    setUser(state, { id, fullName, email, isActive, admin, createdAt, updatedAt }) {
      state.isAuthenticated = true;
      state.user = { id, fullName, email, isActive, admin, createdAt, updatedAt };
    },
  },
  actions: {
    async fetchUser({ commit }) {
      try {
        const user = await getApi().user.me();
        commit('setUser', user);
      } catch (err) {
        console.error('Error:', err);
      }
    },
  },
  getters: {
    user: state => state.user,
    isAuthenticated: state => state.isAuthenticated,
  },
};

export default user;
