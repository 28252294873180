<template>
  <v-card tile>
    <v-img height="200" src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"></v-img>

    <v-row class="personal-info-container">
      <v-list-item>
        <v-list-item-avatar size="120" class="avatar">
          <img :src="gravatar">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title class="title">
            {{ user.fullName }}
          </v-list-item-title>

          <v-list-item-subtitle>
            {{ user.email }}
          </v-list-item-subtitle>
        </v-list-item-content>

        <v-btn icon @click="edit = true">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </v-list-item>
    </v-row>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import md5 from 'md5';

export default {
  computed: {
    ...mapState('user', ['user']),
    gravatar() {
      return 'https://www.gravatar.com/avatar/' + (this.user?.email ? md5(this.user.email) : '');
    }
  },
  data() {
    return {
      edit: false,
    };
  },
}
</script>

<style scoped>
.personal-info-container {
  padding: 2.5%;
  position: absolute;
  top: 130px;
  width: 100%;
}

.avatar {
  border: solid white 4px;
}
</style>
