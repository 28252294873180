<template>
  <v-app-bar app color="primary" dark>
    <div class="d-flex align-center">
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </div>

    <v-spacer></v-spacer>

    <v-menu
      v-if="isAuthenticated"
      bottom
      offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          text
        >
          <span class="mr-2">{{user.fullName}}</span>
          <v-icon>mdi-account</v-icon>
        </v-btn>
      </template>

      <v-list dense>
        <v-list-item-group>
          <router-link
            tag="div"
            to="/"
            class="v-list-item v-list-item--link theme--light"
          >
            <v-list-item-icon>
              <v-icon>mdi-link</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span>Short URLs</span>
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
          <router-link
            tag="div"
            to="/profile"
            class="v-list-item v-list-item--link theme--light"
          >
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                <span>Profile</span>
              </v-list-item-title>
            </v-list-item-content>
          </router-link>
        </v-list-item-group>

        <v-divider class="mt-3 mb-3"></v-divider>

        <v-list-item-group>
          <v-list-item @click="logout()">
            <v-list-item-content>
              <v-list-item-title>Sign Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'NavBar',
  computed: {
    ...mapState('user', ['isAuthenticated', 'user']),
    title() {
      return this.$route.meta?.title || this.$route.name || '';
    },
  },
  methods: {
    logout() {
      this.$api.setToken('');
      if (this.$router.history?.current?.path !== '/signin') {
        this.$router.push('/signin');
      }
    },
  },
}
</script>
