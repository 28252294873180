import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Profile from '@/views/Profile.vue';
import { getApi } from '@bureau700/core';

const notAuthenticatedGuard = (to, from, next) => {
  try {
    if (!getApi().token) {
      location.href = process.env.VUE_APP_HOST_AUTH;
      return;
    }
    next('/dashboard');
  } catch (err) {
    console.log(err);
  }
};

const authenticatedGuard = (to, from, next) => {
  try {
    if (getApi().token) {
      next();
      return;
    }
    location.href = process.env.VUE_APP_HOST_AUTH;
  } catch (err) {
    console.log(err);
  }
};

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: authenticatedGuard,
  },
  {
    path: '/profile',
    name: 'Profile',
    component: Profile,
    beforeEnter: authenticatedGuard,
    meta: {
      marged: false,
    },
  },
  {
    path: '/signin',
    name: 'Signin',
    beforeEnter: notAuthenticatedGuard,
  },
  {
    path: '/signup',
    name: 'Signup',
    beforeEnter: notAuthenticatedGuard,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
