import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { createStore, ApiClient } from '@bureau700/core';
import vuetify from './plugins/vuetify';
import VueCookie from 'vue-cookie';

Vue.config.productionTip = false;

Vue.use(ApiClient, {
  baseUrl: process.env.VUE_APP_API_ENDPOINT,
  cookieHost: process.env.VUE_APP_COOKIE_DOMAIN,
});

const persistenceKey = 'vuex';
const token = VueCookie.get('token');
const vuexStorage = localStorage.getItem(persistenceKey);
if (!token) {
  location.href = process.env.VUE_APP_HOST_AUTH;
} else if (!vuexStorage) {
  localStorage.setItem(persistenceKey, '{}');
}

const store = createStore({
  persistenceKey,
  async rehydrated(store) {
    await store.dispatch('user/fetchUser');
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
