<template>
  <div class="home">
    <h1>Home</h1>
    <h2>Hello, {{ user.fullName }}!</h2>
    <router-link to="/profile">
      <span>Go to profile</span>
    </router-link>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  name: 'Home',
  computed: {
    ...mapState('user', ['user']),
  },
};
</script>
