<template>
  <v-app>
    <NavBar />

    <v-main :class="{'ma-8': marged}">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar,
  },
  computed: {
    marged() {
      return this.$route.meta?.marged ?? true;
    },
  }
}
</script>
